import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

const HighstockChart = ({vwc, temp, area}) => {
  const [nodata, setNoData] = useState(false);

  useEffect(() => {
    if(vwc.length===0 || temp.length===0){
      setNoData(true);
    }
  }, [vwc,temp]);

  const options = {
    chart: {
      zoomType: "x", // Activeer zoomen op de x-as
    },
    title: {
      text: area,
      align: 'left'
    },
    rangeSelector: {
      selected: 0, // Standaard geselecteerde range (bijvoorbeeld 1 maand)
      allButtonsEnabled: false
    },
    legend: {
      enabled: true
    },
    xAxis: {
      type: "datetime", // Zorgt ervoor dat de x-as als tijd wordt weergegeven
      title: {
        text: "Tijd",
      },
    },
    yAxis: [
      {
        title: {
          text: "Volume Water (m³)", // Linker y-as
        },
        opposite: false, // Zorgt ervoor dat deze y-as links staat
      },
      {
        title: {
          text: "Temperatuur (°C)", // Rechter y-as
        },
        opposite: true, // Zorgt ervoor dat deze y-as rechts staat
      },
    ],
    credits: {
      enabled: true,
      href: "https://www.rainup-pro.nl",
      text: "www.rainup-pro.nl"
    },
    tooltip: {
      shared: true, // Laat een gedeelde tooltip zien voor beide datasets
      valueDecimals: 2
    },
    plotOptions: {
      area: {
          marker: {
              radius: 2
          },
          lineWidth: 1,
          color: {
              linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1
              },
              stops: [
                  [0, 'rgb(199, 113, 243)'],
                  [0.7, 'rgb(76, 175, 254)']
              ]
          },
          states: {
              hover: {
                  lineWidth: 1
              }
          },
          threshold: null
      }
    },
    series: [
      {
        name: "Volume Water Content",
        data: vwc,
        type: "area",
        yAxis: 0, // Toont deze serie op de linker y-as
      },
      {
        name: "Temperatuur",
        data: temp,
        type: "line",
        yAxis: 1, // Toont deze serie op de rechter y-as
        color: "#FF5733", // Oranje kleur voor de temperatuur
      },
    ],
  };

  return(
    <div>
    {nodata ? (
        <Alert>Er is geen data aanwezig</Alert>
    ):(
      <><Wrap><HighchartsReact highcharts={Highcharts} constructorType={'stockChart'} options={options} /></Wrap></>
    )}
  </div>
  ) 
};

export default HighstockChart;

const Wrap = styled.div`
    background: #FFF;
    border-radius: 8px;
    padding: 20px;
`;

const Alert = styled.div`
    background: #F8D7DA;
    // background: #FFF3CD;
    padding: 18px;
    color: #77232B;
    margin-bottom: 22px;
    font-size: 12px;
`;
